/*Imports*/
@import 'index';

/****Collapse****/

.collapse {
    @include flex-center;
    flex-direction: column;
    width: 71.04%;

    /*Responsive mobile*/
    @include responsive (375px , 720px){
        width: 89.33%;
    }

    &__title , &__content {
        width: 100%;
        font-size: $font-size-mdlg;

        /*Responsive mobile*/
        @include responsive (375px , 720px){
            gap: 20px;
            font-size: 13px;
        }
    }

    &__title {
        @include flex-center;
        justify-content: space-between;
        background-color: $main-color;
        padding: 10px 20px 10px 10px;
        margin-bottom: 0;
        margin-top: 30px;
        border-radius: $border-radius-sm;
        height: 52px;
        color: white;

        /*Responsive mobile*/
        @include responsive (375px , 720px){
            height: 30px;
            margin-top: 0px;
            padding: 10px;
        }
    }

    &__content {
        background-color: $secondary-color;
        border-radius: 0 0 $border-radius-sm $border-radius-sm;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        max-height: 0;
        opacity: 0;

        &.active {
            max-height: 175px; 
            padding: 10px 20px 10px 10px;
            opacity: 1;

            /*Responsive mobile*/
            @include responsive (375px , 720px){
                padding: 10px;
                max-height: 140px;
            }
        }
    }
}

.arrow {
    transition: transform 0.3s ease-out;

    /*Responsive mobile*/
    @include responsive (375px , 720px){  
        width: 16px;
    }
    
    &.rotate {
        transform: rotate(-180deg);
    }
}

