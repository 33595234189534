/*Imports*/
@import 'index';

/****Card****/

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 55px;
    width: 86.11%;
    padding: 55px 0;
    justify-content: center;
    border-radius: $border-radius-lg;
    margin: 50px 7%;
    background-color: $secondary-color;

    /*Responsive mobile*/
    @include responsive (375px , 720px){
        gap: 22px;
        width: 89.33%;
        margin: 0 5.33%;
        padding: 0;
        background-color: white;
    }
}

.card , .card-image {
    border-radius: $border-radius-md;
    width: 340px;
    height: 340px;

    /*Responsive mobile*/
    @include responsive (375px , 720px){
        width: 100%;
        height: 255px; 
    }
}

.card {
    background:$main-color linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
    
    &-link {
        text-decoration: none;

        /*Responsive mobile*/
        @include responsive (375px , 720px){
            width: 100%;
        }
    }

    &-image {
        object-fit: cover;
    }

    & p {
        position: relative;
        bottom: 75px;
        left: 20px;
        width: 300px;
        @include text-style(white , $font-size-md , 700);
    }
}