/****Index****/

/*Imports*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


/*Variables*/
$main-color:rgba(255, 96, 96, 1);
$secondary-color: rgba(246, 246, 246, 1);
$text-color: black;
$font-family: 'Montserrat';
$max-width: 1440px;

$font-size-lg: 36px;
$font-size-mdlg:24px;
$font-size-md: 18px;
$font-size-sm: 14px;
$font-size-xsm: 12px;

$border-radius-xlg: 50px;
$border-radius-lg: 25px;
$border-radius-md: 10px;
$border-radius-sm: 5px;

$shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


/*Mixins*/
@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin responsive($min, $max) {
    @media screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin text-style($color, $size, $weight: 500) {
    color: $color;
    font-size: $size;
    font-weight: $weight;
}


/*Réinitialisation CSS*/
* {
    box-sizing:border-box;
}

body, html {
    margin: 0;
    padding: 0;
    max-width: $max-width;
    font-family: $font-family;
}

content {
    display:block;
}